import React, { Fragment, useState, useEffect } from 'react';
import Formulario from './components/Formulario';
import Cards from './components/Cards';
import NoDataFound from './components/ParaisosFiscales';
import Detalle from './components/Detalle';
import Spinner from './components/Spinner';
import Login from './components/Login';

import ParaisosFiscales from './components/ParaisosFiscales';

function App() {

	const [ logged, setLogged ] = useState(false);
	const [ user, setUser ] = useState('');
	const [ passwd, setPasswd ] = useState('');
	const [ login, setLogin ] = useState(false);
	const [ token, setToken ] = useState('');

	const [ resultado, setResultado ] = useState([]);
	const [ cargando, setCargando ] = useState(false);
	const [ nombre, setNombre ] = useState('');
	const [ detalleResultados, setDetalleResultados ] = useState('');
	const [ mostarDetalle, setMostarDetalle ] = useState(false);

	const [ paraisosFiscales, setParaisosFiscales ] = useState(false);
	const [ paisParaisoFiscal, setPaisParaisoFiscal ] = useState();

	const [ verPdf, setVerPdf ] = useState(false);

	const consultarLogin = async () => {

		checkIfLogged();

		if((user.length>0)&&(passwd.length>0)) {
			const url = `https://webservice.fibolist.com/login?user=${user}&password=${passwd}&active=1`;
			
			const api = await fetch(url);
			const tkn = await api.json();
			console.log("tkn: ", tkn);

			if(tkn.valid === 1) {
				setToken(tkn.token); 
				setLogged(true);
				setNombre('');
				localStorage.setItem('fwstkn', tkn.token);
			}else{
				setLogged(false);
				setLogin(false);
				setNombre('');
				setParaisosFiscales(false);
				localStorage.removeItem('fwstkn');
			}
		}
	}

	function checkIfLogged() {
		const tkn = localStorage.getItem('fwstkn');
		console.log("tkn: ", tkn);
		if(tkn) {
			setToken(tkn.token); 
			setLogged(true);
		} else {
			setLogged(false);
			setLogin(false);
			localStorage.removeItem('fwstkn');
		}
	
	}

	useEffect( () => {
		consultarLogin();
		// eslint-disable-next-line
	}, [ login ]); 

	const consultarAPI = async () => {
		setResultado({}); 

		setMostarDetalle(false);
		setDetalleResultados('');

		if(nombre.length>0) {
			const token = localStorage.getItem('fwstkn');

			const url = `https://webservice.fibolist.com/search?name=${nombre}&token=${token}`;
			
			const api = await fetch(url);
			const resultado = await api.json();
			
			setResultado(resultado); 
			setCargando(false);
		}
	}

	const consultarTitulosRegimenes = async () => {
		setResultado({}); 

		setMostarDetalle(false);
		setDetalleResultados('');

		if(nombre.length>0) {
			const token = localStorage.getItem('fwstkn');

			const url = `https://webservice.fibolist.com/search?name=${nombre}&token=${token}`;
			
			const api = await fetch(url);
			const resultado = await api.json();
			
			setResultado(resultado); 
			setCargando(false);
		}
	}



	function logout() {
		setLogged(false);
		setUser('');
		setPasswd('');
		setLogin(false);
		setNombre('');
		localStorage.removeItem('fwstkn');
	}

	useEffect( () => {
		consultarAPI();
		// eslint-disable-next-line
	}, [ cargando ]); 

	console.log("paraisosFiscales: ", paraisosFiscales);

	// Mensaje condicional
	const componente = 
		(mostarDetalle)
		? <Detalle
			detalleResultados = { detalleResultados }
			setCargando =  { setCargando }
			token = { token }
		/>
		:( (resultado.message === 'No data found')&&(nombre.length>0) ) 
		? <NoDataFound nombre = { nombre } />
		: (((typeof detalleResultados === undefined)||(Object.keys(detalleResultados).length === 0))&&(cargando === false))
		? <Cards 
			resultado = { resultado } 
			setDetalleResultados = { setDetalleResultados } 
			setMostarDetalle = { setMostarDetalle }
		/>
		: <Spinner />

	return (
		<Fragment>
			{(logged)
			? (verPdf)
				? 
					null
				:
					(<div className="container"><br />
						<div>
							
							<button 
								className="button cerrar"
								onClick = { logout }
							>
							Cerrar sesión
							</button>
							
						</div>
						<div className="row">
							<div className="one-half column">
								<Formulario 
									setCargando = { setCargando }
									nombre = { nombre }
									setNombre = { setNombre }
									setLogged = { setLogged }
									setUser = { setUser }
									setPasswd = { setPasswd }
									setLogin = { setLogin }
									setParaisosFiscales = { setParaisosFiscales }
									setPaisParaisoFiscal = { setPaisParaisoFiscal }
								/>
							</div>
							<div className="one-half column">
								<h2>Resultados</h2>
								{(paraisosFiscales)
								? <ParaisosFiscales 
									setParaisosFiscales = { setParaisosFiscales }
									paisParaisoFiscal = { paisParaisoFiscal }
								/>
								:
								(resultado.length) > 0
								? <h4>{resultado.length} encontrados</h4>
								: null}
								{ componente }
							</div>
						</div>
					</div>)
			: 
				<div className="container">
					<div className="row">
						<Login 
							user = { user }
							setUser = { setUser }
							passwd = { passwd }
							setPasswd = { setPasswd }
							login = { login }
							setLogin = { setLogin }
						/>
					</div>
				</div>
			}	
		</Fragment>
	);
}

export default App;
