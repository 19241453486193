import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const Formulario = ({ 
	setCargando
	, nombre
	, setNombre
	// , setLogged
	// , setUser
	// , setPasswd
	// , setLogin 
	// , setRegimenes
	, setParaisosFiscales
	, setPaisParaisoFiscal
	}) => {

	// const [estePais, setEstePais] = useState('');

	const [error, setError] = useState(false);
	let actualizarState = e => { 
		setNombre(e.target.value);
		setParaisosFiscales(false);
	}
	const [errorMsg, setErrorMsg] = useState('');
	const regexp = /[a-zA-Z]+\s+[a-zA-Z]+/g;

	const submitFormulario = e => {
		e.preventDefault();
		if(nombre.trim() === '') {
			setError(true);
			setErrorMsg('El nombre es obligatorio');
			return;
		}
		if (!(regexp.test(nombre.trim()))){
			setError(true);
			setErrorMsg('Deben ser por lo menos dos palabras');
			return;
		}
			setError(false);
			setErrorMsg('');
			setCargando(true);		
			setParaisosFiscales(false);
	}

	const spf_mexico = () => {
		setParaisosFiscales(true);
		setPaisParaisoFiscal('mexico');
	}

	const spf_gafi = () => {
		setParaisosFiscales(true);
		setPaisParaisoFiscal('FATF-GAFI');
	}
	
	const spf_colombia = () => {
		setParaisosFiscales(true);
		setPaisParaisoFiscal('colombia');
	}
	const spf_peru = () => {
		setParaisosFiscales(true);
		setPaisParaisoFiscal('peru');
	}
	const spf_venezuela = () => {
		setParaisosFiscales(true);
		setPaisParaisoFiscal('venezuela');
	}
	
	return ( 
		<Fragment>
			<h2>
				Buscar
			</h2>

			{/* { error ? <p className="alerta-error">{errorMsg}</p> : null } */}
			<form
				onSubmit = { submitFormulario }
			>

				{/* <label>Nombre</label> */}
				<input
					type 		= "text"
					name 		= "nombre"
					className 	= "u-full-width"
					placeholder = "Nombre a buscar"
					onChange 	= { actualizarState }
					// value 		= { nombre }
				/>



				<button
					type 		= "submit"
					className 	= "u-full-width button-primary"
				>Buscar</button>

				</form>
				{ error ? <div className="cita alerta-error">
						{ errorMsg }
					</div>
					: null }
				
				<br />
				<br />
				<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf_mexico }
				>México: Regímenes Fiscales Preferentes</button>
				<br />
				<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf_gafi }
				>FATF-GAFI: HIGH-RISK AND NON-COOPERATIVE JURISDICTIONS</button>
				<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf_colombia }
				>COLOMBIA: PARAISOS FISCALES</button>
				<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf_peru }
				>PERU: PAISES O TERRITORIOS DE BAJA O NULA IMPOSICION</button>
				<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf_venezuela }
				>VENEZUELA: JURISDICCION DE BAJA IMPOSICION FISCAL</button>

		</Fragment>
	);
}

Formulario.propTypes = {
	setCargando: PropTypes.func.isRequired,
	nombre: PropTypes.string.isRequired,
	setNombre: PropTypes.func.isRequired,
}

export default Formulario;