import React, { Fragment } from 'react'
import Card from './Card';
import PropTypes from 'prop-types';

const Cards = ({ resultado, setDetalleResultados, setMostarDetalle  }) => {

	if((typeof resultado === undefined)||(Object.keys(resultado).length === 0)) return null;
	
	const componente = ( (Object.keys(resultado).length) === 0 )
						? null
						: resultado.map(res => (
							<Card
								key = { res.id }
								resultado = { res }
								setDetalleResultados = { setDetalleResultados }
								setMostarDetalle = { setMostarDetalle }
							/>
						))

	return ( 
		<Fragment>			
			{componente}
		</Fragment>
	 );
}

Cards.propTypes = {
	// resultado: PropTypes.object.isRequired, // FIXME!!!
	setDetalleResultados: PropTypes.func.isRequired,
	setMostarDetalle: PropTypes.func.isRequired,
}

export default Cards;