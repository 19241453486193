import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

const Card = ({ resultado, setDetalleResultados, setMostarDetalle }) => {

	const { id, catalogo, datetime, pep, nombre } = resultado;

	const componente1 = (datetime !== '0000-00-00 00:00:00') 
	? <p>Última actualización: <span>{ datetime }</span></p>
	: null;

	const componente2 = (pep === "1") 
	? <p>PEP: <span>SI</span></p>
	: null;

	const verDetalle = id => {
		setDetalleResultados(resultado);
		setMostarDetalle(true);
	}

	return ( 
		<Fragment>
			<div className="cita">
				<p>Nombre: <span>{ nombre }</span></p>
				<p>Catálogo: <span>{ catalogo }</span></p>
				{ componente1 }
				{ componente2 }
				<button 
					className="button eliminar u-full-width"
					type="button"
					onClick = { () => verDetalle(id) }
				>
				Detalles	
				</button>
			</div>
		</Fragment>
	 );
}
 
Card.propTypes = {
	resultado: PropTypes.object.isRequired,
	setDetalleResultados: PropTypes.func.isRequired,
	setMostarDetalle: PropTypes.func.isRequired,
}

export default Card;