import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable'


const Detalle = ({ detalleResultados, setCargando }) => {

	const { id } = detalleResultados;
	const [ detalleConsulta, setDetalleConsulta ] = useState('');
	
	const consultarAPIDetalle = async () => {

		if(id > 0) {

			const token = localStorage.getItem('fwstkn');

			const url = `https://webservice.fibolist.com/details?id=${id}&token=${token}`;
			
			const api = await fetch(url);
			const resultado = await api.json();
			console.log(resultado);
			
			setDetalleConsulta(resultado); 
		}
	}

	const generatePDF = () => {
		let doc = new jsPDF('p', 'mm', 'letter', 1);

		// Cabecera
		doc.setFontSize('20');
		doc.setFont('Roboto-Regular', 'bold', )
		doc.text('FiboLIST', 200, 20, 'right');
		doc.setFontSize('12');
		doc.setFont('Roboto-Regular', 'normal')
		doc.text(`Consulta: ${detalleConsulta.curdate}`, 200, 25, 'right');
		
		
		// Sujeto
		doc.setFontSize('16');
		doc.setFont('Roboto-Regular', 'bold')
		let text = detalleConsulta.nombre;
		let textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
		let textOffset = (doc.internal.pageSize.width - textWidth) / 2;
		doc.text(textOffset, 35, text);
		
		
		doc.setFontSize('12');
		doc.setFont('Roboto-Regular', 'normal');

		let body = [];
	
		if((typeof detalleConsulta.pep !== undefined) && (detalleConsulta.pep) && (detalleConsulta.pep.length) && (detalleConsulta.pep !== '') ) { body.push(['PEP:' , 'SI']); }
		if((typeof detalleConsulta.tipo_lista !== undefined) && (detalleConsulta.tipo_lista) && (detalleConsulta.tipo_lista.length) && (detalleConsulta.tipo_lista !== '') ) { 
			if(detalleConsulta.tipo_lista === 'R' ) { body.push(['Tipo de Lista:' , 'Restrictiva']); 
			}else if(detalleConsulta.tipo_lista === 'P' ) { body.push(['Tipo de Lista:' , 'Preventiva']);
			}else{ body.push(['Tipo de Lista:' , 'Restrictiva']); } } 
		if((typeof detalleConsulta.catalogo !== undefined) && (detalleConsulta.catalogo) && (detalleConsulta.catalogo.length) && (detalleConsulta.catalogo !== '') ) { body.push(['Catálogo:' , detalleConsulta.catalogo]); }
		if((typeof detalleConsulta.programa !== undefined) && (detalleConsulta.programa) && (detalleConsulta.programa.length) && (detalleConsulta.programa !== '') ) { body.push(['Programa:' , detalleConsulta.programa]); }
		if((typeof detalleConsulta.domicilio !== undefined) && (detalleConsulta.domicilio) && (detalleConsulta.domicilio.length) && (detalleConsulta.domicilio !== '') ) { body.push(['Domicilio:' , detalleConsulta.domicilio]); }
		if((typeof detalleConsulta.ciudad !== undefined) && (detalleConsulta.ciudad) && (detalleConsulta.ciudad.length) && (detalleConsulta.ciudad !== '') ) { body.push(['Ciudad:' , detalleConsulta.ciudad]); }
		if((typeof detalleConsulta.estado !== undefined) && (detalleConsulta.estado) && (detalleConsulta.estado.length) && (detalleConsulta.estado !== '') ) { body.push(['Estado:' , detalleConsulta.estado]); }
		if((typeof detalleConsulta.pais !== undefined) && (detalleConsulta.pais) && (detalleConsulta.pais.length) && (detalleConsulta.pais !== '') ) { body.push(['País:' , detalleConsulta.pais]); }
		if((typeof detalleConsulta.cp !== undefined) && (detalleConsulta.cp) && (detalleConsulta.cp.length) && (detalleConsulta.cp !== '') ) { body.push(['Código Postal:' , detalleConsulta.cp]); }
		if((typeof detalleConsulta.alias !== undefined) && (detalleConsulta.alias) && (detalleConsulta.alias.length) && (detalleConsulta.alias !== '') ) { body.push(['Alias:' , detalleConsulta.alias]); }
		if((typeof detalleConsulta.nombre_cargo !== undefined) && (detalleConsulta.nombre_cargo) && (detalleConsulta.nombre_cargo.length) && (detalleConsulta.nombre_cargo !== '') ) { body.push(['Nombre del Cargo:' , detalleConsulta.nombre_cargo]); }
		if((typeof detalleConsulta.nombre_cargo_superior !== undefined) && (detalleConsulta.nombre_cargo_superior) && (detalleConsulta.nombre_cargo_superior.length) && (detalleConsulta.nombre_cargo_superior !== '') ) { body.push(['Nombre del Cargo Superior:' , detalleConsulta.nombre_cargo_superior]); }
		if((typeof detalleConsulta.unidad_administrativa !== undefined) && (detalleConsulta.unidad_administrativa) && (detalleConsulta.unidad_administrativa.length) && (detalleConsulta.unidad_administrativa !== '') ) { body.push(['Unidad Administrativa:' , detalleConsulta.unidad_administrativa]); }
		if((typeof detalleConsulta.tipo_personal !== undefined) && (detalleConsulta.tipo_personal) && (detalleConsulta.tipo_personal.length) && (detalleConsulta.tipo_personal !== '') ) { body.push(['Tipo de Personal:' , detalleConsulta.tipo_personal]); }
		if((typeof detalleConsulta.telefono_directo !== undefined) && (detalleConsulta.telefono_directo) && (detalleConsulta.telefono_directo.length) && (detalleConsulta.telefono_directo !== '') ) { body.push(['Teléfono Directo:' , detalleConsulta.telefono_directo]); }
		if((typeof detalleConsulta.telefono !== undefined) && (detalleConsulta.telefono) && (detalleConsulta.telefono.length) && (detalleConsulta.telefono !== '') ) { body.push(['Teléfono:' , detalleConsulta.telefono]); }
		if((typeof detalleConsulta.extension !== undefined) && (detalleConsulta.extension) && (detalleConsulta.extension.length) && (detalleConsulta.extension !== '') ) { body.push(['Extensión:' , detalleConsulta.extension]); }
		if((typeof detalleConsulta.email !== undefined) && (detalleConsulta.email) && (detalleConsulta.email.length) && (detalleConsulta.email !== '') ) { body.push(['Correo Electrónico:' , detalleConsulta.email]); }
		if((typeof detalleConsulta.ambito_o_entidad !== undefined) && (detalleConsulta.ambito_o_entidad) && (detalleConsulta.ambito_o_entidad.length) && (detalleConsulta.ambito_o_entidad !== '') ) { body.push(['Ambito o Entidad:' , detalleConsulta.ambito_o_entidad]); }
		if((typeof detalleConsulta.en_funciones_desde !== undefined) && (detalleConsulta.en_funciones_desde) && (detalleConsulta.en_funciones_desde.length) && (detalleConsulta.en_funciones_desde !== '') ) { body.push(['En funciones desde:' , detalleConsulta.en_funciones_desde]); }
		if((typeof detalleConsulta.en_funciones_hasta !== undefined) && (detalleConsulta.en_funciones_hasta) && (detalleConsulta.en_funciones_hasta.length) && (detalleConsulta.en_funciones_hasta !== '') ) { body.push(['En funciones hasta:' , detalleConsulta.en_funciones_hasta]); }
		if((typeof detalleConsulta.fecha_nacimiento !== undefined) && (detalleConsulta.fecha_nacimiento) && (detalleConsulta.fecha_nacimiento.length) && (detalleConsulta.fecha_nacimiento !== '') ) { body.push(['Fecha de nacimiento:' , detalleConsulta.fecha_nacimiento]); }
		if((typeof detalleConsulta.lugar_nacimiento !== undefined) && (detalleConsulta.lugar_nacimiento) && (detalleConsulta.lugar_nacimiento.length) && (detalleConsulta.lugar_nacimiento !== '') ) { body.push(['Lugar de nacimiento:' , detalleConsulta.lugar_nacimiento]); }
		if((typeof detalleConsulta.partido_politico !== undefined) && (detalleConsulta.partido_politico) && (detalleConsulta.partido_politico.length) && (detalleConsulta.partido_politico !== '') ) { body.push(['Partido político:' , detalleConsulta.partido_politico]); }
		if((typeof detalleConsulta.rfc !== undefined) && (detalleConsulta.rfc) && (detalleConsulta.rfc.length) && (detalleConsulta.rfc !== '') ) { body.push(['RFC:' , detalleConsulta.rfc]); }
		if((typeof detalleConsulta.curp !== undefined) && (detalleConsulta.curp) && (detalleConsulta.curp.length) && (detalleConsulta.curp !== '') ) { body.push(['CURP:' , detalleConsulta.curp]); }
		if((typeof detalleConsulta.web !== undefined) && (detalleConsulta.web) && (detalleConsulta.web.length) && (detalleConsulta.web !== '') ) { body.push(['WEB:' , detalleConsulta.web]); }
		if((typeof detalleConsulta.fuente_informacion !== undefined) && (detalleConsulta.fuente_informacion) && (detalleConsulta.fuente_informacion.length) && (detalleConsulta.fuente_informacion !== '') ) { body.push(['Fuente de la información:' , detalleConsulta.fuente_informacion]); }
		if((typeof detalleConsulta.escolaridad !== undefined) && (detalleConsulta.escolaridad) && (detalleConsulta.escolaridad.length) && (detalleConsulta.escolaridad !== '') ) { body.push(['Escolaridad:' , detalleConsulta.escolaridad]); }
		if((typeof detalleConsulta.experiencia_profesional !== undefined) && (detalleConsulta.experiencia_profesional) && (detalleConsulta.experiencia_profesional.length) && (detalleConsulta.experiencia_profesional !== '') ) { body.push(['Experiencia Profesional:' , detalleConsulta.experiencia_profesional]); }
		if((typeof detalleConsulta.redes_sociales !== undefined) && (detalleConsulta.redes_sociales) && (detalleConsulta.redes_sociales.length) && (detalleConsulta.redes_sociales !== '') ) { body.push(['Redes sociales:' , detalleConsulta.redes_sociales]); }
		if((typeof detalleConsulta.parentescos !== undefined) && (detalleConsulta.parentescos) && (detalleConsulta.parentescos.length) && (detalleConsulta.parentescos !== '') ) { body.push(['Parentescos:' , detalleConsulta.parentescos]); }
		if((typeof detalleConsulta.fax !== undefined) && (detalleConsulta.fax) && (detalleConsulta.fax.length) && (detalleConsulta.fax !== '') ) { body.push(['Fax:' , detalleConsulta.fax]); }
		if((typeof detalleConsulta.nombre_puesto !== undefined) && (detalleConsulta.nombre_puesto) && (detalleConsulta.nombre_puesto.length) && (detalleConsulta.nombre_puesto !== '') ) { body.push(['Nombre del puesto:' , detalleConsulta.nombre_puesto]); }
		if((typeof detalleConsulta.clave_puesto !== undefined) && (detalleConsulta.clave_puesto) && (detalleConsulta.clave_puesto.length) && (detalleConsulta.clave_puesto !== '') ) { body.push(['Clave del puesto:' , detalleConsulta.clave_puesto]); }
		if((typeof detalleConsulta.nivel_jerarquico !== undefined) && (detalleConsulta.nivel_jerarquico) && (detalleConsulta.nivel_jerarquico.length) && (detalleConsulta.nivel_jerarquico !== '') ) { body.push(['Nivel jerárquico:' , detalleConsulta.nivel_jerarquico]); }
		if((typeof detalleConsulta.remark1 !== undefined) && (detalleConsulta.remark1) && (detalleConsulta.remark1.length) && (detalleConsulta.remark1 !== '') ) { body.push(['Remark 1:' , detalleConsulta.remark1]); }
		if((typeof detalleConsulta.remark2 !== undefined) && (detalleConsulta.remark2) && (detalleConsulta.remark2.length) && (detalleConsulta.remark2 !== '') ) { body.push(['Remark 2:' , detalleConsulta.remark2]); }
		if((typeof detalleConsulta.remark3 !== undefined) && (detalleConsulta.remark3) && (detalleConsulta.remark3.length) && (detalleConsulta.remark3 !== '') ) { body.push(['Remark 3:' , detalleConsulta.remark3]); }
		if((typeof detalleConsulta.remark4 !== undefined) && (detalleConsulta.remark4) && (detalleConsulta.remark4.length) && (detalleConsulta.remark4 !== '') ) { body.push(['Remark 4:' , detalleConsulta.remark4]); }
		if((typeof detalleConsulta.remark5 !== undefined) && (detalleConsulta.remark5) && (detalleConsulta.remark5.length) && (detalleConsulta.remark5 !== '') ) { body.push(['Remark 5:' , detalleConsulta.remark5]); }
		if((typeof detalleConsulta.remark6 !== undefined) && (detalleConsulta.remark6) && (detalleConsulta.remark6.length) && (detalleConsulta.remark6 !== '') ) { body.push(['Remark 6:' , detalleConsulta.remark6]); }
		if((typeof detalleConsulta.remark7 !== undefined) && (detalleConsulta.remark7) && (detalleConsulta.remark7.length) && (detalleConsulta.remark7 !== '') ) { body.push(['Remark 7:' , detalleConsulta.remark7]); }
		if((typeof detalleConsulta.remark8 !== undefined) && (detalleConsulta.remark8) && (detalleConsulta.remark8.length) && (detalleConsulta.remark8 !== '') ) { body.push(['Remark 8:' , detalleConsulta.remark8]); }
		if((typeof detalleConsulta.remark9 !== undefined) && (detalleConsulta.remark9) && (detalleConsulta.remark9.length) && (detalleConsulta.remark9 !== '') ) { body.push(['Remark 9:' , detalleConsulta.remark9]); }
		if((typeof detalleConsulta.remark10 !== undefined) && (detalleConsulta.remark10) && (detalleConsulta.remark10.length) && (detalleConsulta.remark10 !== '') ) { body.push(['Remark 10:' , detalleConsulta.remark10]); }
		if((typeof detalleConsulta.vinculo1 !== undefined) && (detalleConsulta.vinculo1) && (detalleConsulta.vinculo1.length) && (detalleConsulta.vinculo1 !== '') ) { body.push(['Vínculo 1:' , detalleConsulta.vinculo1]); }
		if((typeof detalleConsulta.tag1 !== undefined) && (detalleConsulta.tag1) && (detalleConsulta.tag1.length) && (detalleConsulta.tag1 !== '') ) { body.push(['Tag 1:' , detalleConsulta.tag1]); }
		if((typeof detalleConsulta.vinculo2 !== undefined) && (detalleConsulta.vinculo2) && (detalleConsulta.vinculo2.length) && (detalleConsulta.vinculo2 !== '') ) { body.push(['Vínculo 2:' , detalleConsulta.vinculo2]); }
		if((typeof detalleConsulta.tag2 !== undefined) && (detalleConsulta.tag2) && (detalleConsulta.tag2.length) && (detalleConsulta.tag2 !== '') ) { body.push(['Tag 2:' , detalleConsulta.tag2]); }
		if((typeof detalleConsulta.vinculo3 !== undefined) && (detalleConsulta.vinculo3) && (detalleConsulta.vinculo3.length) && (detalleConsulta.vinculo3 !== '') ) { body.push(['Vínculo 3:' , detalleConsulta.vinculo3]); }
		if((typeof detalleConsulta.tag3 !== undefined) && (detalleConsulta.tag3) && (detalleConsulta.tag3.length) && (detalleConsulta.tag3 !== '') ) { body.push(['Tag 3:' , detalleConsulta.tag3]); }
		if((typeof detalleConsulta.vinculo4 !== undefined) && (detalleConsulta.vinculo4) && (detalleConsulta.vinculo4.length) && (detalleConsulta.vinculo4 !== '') ) { body.push(['Vínculo 4:' , detalleConsulta.vinculo4]); }
		if((typeof detalleConsulta.tag4 !== undefined) && (detalleConsulta.tag4) && (detalleConsulta.tag4.length) && (detalleConsulta.tag4 !== '') ) { body.push(['Tag 4:' , detalleConsulta.tag4]); }
		if((typeof detalleConsulta.vinculo5 !== undefined) && (detalleConsulta.vinculo5) && (detalleConsulta.vinculo5.length) && (detalleConsulta.vinculo5 !== '') ) { body.push(['Vínculo 5:' , detalleConsulta.vinculo5]); }
		if((typeof detalleConsulta.tag5 !== undefined) && (detalleConsulta.tag5) && (detalleConsulta.tag5.length) && (detalleConsulta.tag5 !== '') ) { body.push(['Tag 5:' , detalleConsulta.tag5]); }
		if((typeof detalleConsulta.vinculo6 !== undefined) && (detalleConsulta.vinculo6) && (detalleConsulta.vinculo6.length) && (detalleConsulta.vinculo6 !== '') ) { body.push(['Vínculo 6:' , detalleConsulta.vinculo6]); }
		if((typeof detalleConsulta.tag6 !== undefined) && (detalleConsulta.tag6) && (detalleConsulta.tag6.length) && (detalleConsulta.tag6 !== '') ) { body.push(['Tag 6:' , detalleConsulta.tag6]); }
		if((typeof detalleConsulta.vinculo7 !== undefined) && (detalleConsulta.vinculo7) && (detalleConsulta.vinculo7.length) && (detalleConsulta.vinculo7 !== '') ) { body.push(['Vínculo 7:' , detalleConsulta.vinculo7]); }
		if((typeof detalleConsulta.tag7 !== undefined) && (detalleConsulta.tag7) && (detalleConsulta.tag7.length) && (detalleConsulta.tag7 !== '') ) { body.push(['Tag 7:' , detalleConsulta.tag7]); }
		if((typeof detalleConsulta.vinculo8 !== undefined) && (detalleConsulta.vinculo8) && (detalleConsulta.vinculo8.length) && (detalleConsulta.vinculo8 !== '') ) { body.push(['Vínculo 8:' , detalleConsulta.vinculo8]); }
		if((typeof detalleConsulta.tag8 !== undefined) && (detalleConsulta.tag8) && (detalleConsulta.tag8.length) && (detalleConsulta.tag8 !== '') ) { body.push(['Tag 8:' , detalleConsulta.tag8]); }
		if((typeof detalleConsulta.vinculo9 !== undefined) && (detalleConsulta.vinculo9) && (detalleConsulta.vinculo9.length) && (detalleConsulta.vinculo9 !== '') ) { body.push(['Vínculo 9:' , detalleConsulta.vinculo9]); }
		if((typeof detalleConsulta.tag9 !== undefined) && (detalleConsulta.tag9) && (detalleConsulta.tag9.length) && (detalleConsulta.tag9 !== '') ) { body.push(['Tag 9:' , detalleConsulta.tag9]); }
		if((typeof detalleConsulta.vinculo10 !== undefined) && (detalleConsulta.vinculo10) && (detalleConsulta.vinculo10.length) && (detalleConsulta.vinculo10 !== '') ) { body.push(['Vínculo 10:' , detalleConsulta.vinculo10]); }
		if((typeof detalleConsulta.tag10 !== undefined) && (detalleConsulta.tag10) && (detalleConsulta.tag10.length) && (detalleConsulta.tag10 !== '') ) { body.push(['Tag 10:' , detalleConsulta.tag10]); }

		console.log("body: ", body);
		doc.autoTable({
			startY: 40,
			body: body
		})
	
		// doc.save('demo.pdf')
		doc.save(`${detalleConsulta.id}.pdf`)
	}

	useEffect( () => {
		consultarAPIDetalle();
		// eslint-disable-next-line
	}, []); 

	console.log("detalleConsulta: ", detalleConsulta);


	return ( 
		<Fragment>
			<div className="cita">
				{ (detalleConsulta.nombre) ? <p>Nombre completo <span> { detalleConsulta.nombre } </span> </p>: null }
				{ (detalleConsulta.pep) ? <p>PEP: SI</p>: null }
				{ (detalleConsulta.catalogo) ? <p>Catálogo <span> { detalleConsulta.catalogo } </span> </p>: null }
				{ (detalleConsulta.tipo_lista) ? <p>Tipo de Lista <span> { (detalleConsulta.tipo_lista === 'R') ? 'Restrictiva' : (detalleConsulta.tipo_lista === 'P') ? 'Preventiva': 'Informativa' } </span> </p>: null }
				{ (detalleConsulta.programa) ? <p>Programa <span> { detalleConsulta.programa } </span> </p>: null }
				{ (detalleConsulta.domicilio) ? <p>Domicilio <span> { detalleConsulta.domicilio } </span> </p>: null }
				{ (detalleConsulta.ciudad) ? <p>Ciudad <span> { detalleConsulta.ciudad } </span> </p>: null }
				{ (detalleConsulta.estado) ? <p>Estado <span> { detalleConsulta.estado } </span> </p>: null }
				{ (detalleConsulta.pais) ? <p>País <span> { detalleConsulta.pais } </span> </p>: null }
				{ (detalleConsulta.cp) ? <p>Código Postal <span> { detalleConsulta.cp } </span> </p>: null }
				{ (detalleConsulta.alias) ? <p>Alias <span> { detalleConsulta.alias } </span> </p>: null }
				{ (detalleConsulta.nombre_cargo) ? <p>Nombre del Cargo <span> { detalleConsulta.nombre_cargo } </span> </p>: null }
				{ (detalleConsulta.nombre_cargo_superior) ? <p>Nombre del Cargo Superior <span> { detalleConsulta.nombre_cargo_superior } </span> </p>: null }
				{ (detalleConsulta.unidad_administrativa) ? <p>Unidad Administrativa <span> { detalleConsulta.unidad_administrativa } </span> </p>: null }
				{ (detalleConsulta.tipo_personal) ? <p>Tipo de Personal <span> { detalleConsulta.tipo_personal } </span> </p>: null }
				{ (detalleConsulta.telefono_directo) ? <p>Teléfono Directo <span> { detalleConsulta.telefono_directo } </span> </p>: null }
				{ (detalleConsulta.telefono) ? <p>Teléfono <span> { detalleConsulta.telefono } </span> </p>: null }
				{ (detalleConsulta.extension) ? <p>Extensión <span> { detalleConsulta.extension } </span> </p>: null }
				{ (detalleConsulta.email) ? <p>Correo Electrónico <span> { detalleConsulta.email } </span> </p>: null }
				{ (detalleConsulta.ambito_o_entidad) ? <p>Ambito o Entidad <span> { detalleConsulta.ambito_o_entidad } </span> </p>: null }
				{ (detalleConsulta.en_funciones_desde) ? <p>En funciones desde <span> { detalleConsulta.en_funciones_desde } </span> </p>: null }
				{ (detalleConsulta.en_funciones_hasta) ? <p>En funciones hasta <span> { detalleConsulta.en_funciones_hasta } </span> </p>: null }
				{ (detalleConsulta.fecha_nacimiento) ? <p>Fecha de nacimiento <span> { detalleConsulta.fecha_nacimiento } </span> </p>: null }
				{ (detalleConsulta.lugar_nacimiento) ? <p>Lugar de nacimiento <span> { detalleConsulta.lugar_nacimiento } </span> </p>: null }
				{ (detalleConsulta.partido_politico) ? <p>Partido político <span> { detalleConsulta.partido_politico } </span> </p>: null }
				{ (detalleConsulta.rfc) ? <p>RFC <span> { detalleConsulta.rfc } </span> </p>: null }
				{ (detalleConsulta.curp) ? <p>CURP <span> { detalleConsulta.curp } </span> </p>: null }
				{ (detalleConsulta.web) ? <p>WEB <span> { detalleConsulta.web } </span> </p>: null }
				{ (detalleConsulta.fuente_informacion) ? <p>Fuente de la información <span> { detalleConsulta.fuente_informacion } </span> </p>: null }
				{ (detalleConsulta.escolaridad) ? <p>Escolaridad <span> { detalleConsulta.escolaridad } </span> </p>: null }
				{ (detalleConsulta.experiencia_profesional) ? <p>Experiencia Profesional <span> { detalleConsulta.experiencia_profesional } </span> </p>: null }
				{ (detalleConsulta.redes_sociales) ? <p>Redes sociales <span> { detalleConsulta.redes_sociales } </span> </p>: null }
				{ (detalleConsulta.parentescos) ? <p>Parentescos <span> { detalleConsulta.parentescos } </span> </p>: null }
				{ (detalleConsulta.fax) ? <p>Fax <span> { detalleConsulta.fax } </span> </p>: null }
				{ (detalleConsulta.nombre_puesto) ? <p>Nombre del puesto <span> { detalleConsulta.nombre_puesto } </span> </p>: null }
				{ (detalleConsulta.clave_puesto) ? <p>Clave del puesto <span> { detalleConsulta.clave_puesto } </span> </p>: null }
				{ (detalleConsulta.nivel_jerarquico) ? <p>Nivel jerárquico <span> { detalleConsulta.nivel_jerarquico } </span> </p>: null }
				{ (detalleConsulta.remark1) ? <p>Remark 1 <span> { detalleConsulta.remark1 } </span> </p>: null }
				{ (detalleConsulta.remark2) ? <p>Remark 2 <span> { detalleConsulta.remark2 } </span> </p>: null }
				{ (detalleConsulta.remark3) ? <p>Remark 3 <span> { detalleConsulta.remark3 } </span> </p>: null }
				{ (detalleConsulta.remark4) ? <p>Remark 4 <span> { detalleConsulta.remark4 } </span> </p>: null }
				{ (detalleConsulta.remark5) ? <p>Remark 5 <span> { detalleConsulta.remark5 } </span> </p>: null }
				{ (detalleConsulta.remark6) ? <p>Remark 6 <span> { detalleConsulta.remark6 } </span> </p>: null }
				{ (detalleConsulta.remark7) ? <p>Remark 7 <span> { detalleConsulta.remark7 } </span> </p>: null }
				{ (detalleConsulta.remark8) ? <p>Remark 8 <span> { detalleConsulta.remark8 } </span> </p>: null }
				{ (detalleConsulta.remark9) ? <p>Remark 9 <span> { detalleConsulta.remark9 } </span> </p>: null }
				{ (detalleConsulta.remark10) ? <p>Remark 10 <span> { detalleConsulta.remark10 } </span> </p>: null }
				{ (detalleConsulta.vinculo1) ? <p>Vínculo 1 <span> { detalleConsulta.vinculo1 } </span> </p>: null }
				{ (detalleConsulta.tag1) ? <p>Tag 1 <span> { detalleConsulta.tag1 } </span> </p>: null }
				{ (detalleConsulta.vinculo2) ? <p>Vínculo 2 <span> { detalleConsulta.vinculo2 } </span> </p>: null }
				{ (detalleConsulta.tag2) ? <p>Tag 2 <span> { detalleConsulta.tag2 } </span> </p>: null }
				{ (detalleConsulta.vinculo3) ? <p>Vínculo 3 <span> { detalleConsulta.vinculo3 } </span> </p>: null }
				{ (detalleConsulta.tag3) ? <p>Tag 3 <span> { detalleConsulta.tag3 } </span> </p>: null }
				{ (detalleConsulta.vinculo4) ? <p>Vínculo 4 <span> { detalleConsulta.vinculo4 } </span> </p>: null }
				{ (detalleConsulta.tag4) ? <p>Tag 4 <span> { detalleConsulta.tag4 } </span> </p>: null }
				{ (detalleConsulta.vinculo5) ? <p>Vínculo 5 <span> { detalleConsulta.vinculo5 } </span> </p>: null }
				{ (detalleConsulta.tag5) ? <p>Tag 5 <span> { detalleConsulta.tag5 } </span> </p>: null }
				{ (detalleConsulta.vinculo6) ? <p>Vínculo 6 <span> { detalleConsulta.vinculo6 } </span> </p>: null }
				{ (detalleConsulta.tag6) ? <p>Tag 6 <span> { detalleConsulta.tag6 } </span> </p>: null }
				{ (detalleConsulta.vinculo7) ? <p>Vínculo 7 <span> { detalleConsulta.vinculo7 } </span> </p>: null }
				{ (detalleConsulta.tag7) ? <p>Tag 7 <span> { detalleConsulta.tag7 } </span> </p>: null }
				{ (detalleConsulta.vinculo8) ? <p>Vínculo 8 <span> { detalleConsulta.vinculo8 } </span> </p>: null }
				{ (detalleConsulta.tag8) ? <p>Tag 8 <span> { detalleConsulta.tag8 } </span> </p>: null }
				{ (detalleConsulta.vinculo9) ? <p>Vínculo 9 <span> { detalleConsulta.vinculo9 } </span> </p>: null }
				{ (detalleConsulta.tag9) ? <p>Tag 9 <span> { detalleConsulta.tag9 } </span> </p>: null }
				{ (detalleConsulta.vinculo10) ? <p>Vínculo 10 <span> { detalleConsulta.vinculo10 } </span> </p>: null }
				{ (detalleConsulta.tag10) ? <p>Tag 10 <span> { detalleConsulta.tag10 } </span> </p>: null }

				<button 
						className="button eliminar u-full-width"
						type="button"
						onClick = { () => setCargando(true) }
				>Regresar</button>
				<button 
						className="button eliminar u-full-width"
						type="button"
						onClick = { () => generatePDF(true) }
				>Descargar PDF</button>
			</div>
		</Fragment>
	);
}

Detalle.propTypes = {
	detalleResultados: PropTypes.object.isRequired,
	setCargando : PropTypes.func.isRequired,
}

export default Detalle;