import React, { Fragment, useState, useEffect } from 'react';
import Spinner from './Spinner';

const ParaisosFiscales =  ({ setParaisosFiscales, paisParaisoFiscal }) => {

	const [ resultado, setResultado ] = useState([]);

	// const spf = () => {
	// 	setParaisosFiscales(false);
	// }
	let head = '';
	if(paisParaisoFiscal === 'mexico' ) {
		head = 'México: Regímenes Fiscales Preferentes' ;
	}else if(paisParaisoFiscal === 'FATF-GAFI' ) {
		head = 'FATF-GAFI: HIGH-RISK AND NON-COOPERATIVE JURISDICTIONS';
	}else if(paisParaisoFiscal === 'colombia' ) {
		head = 'COLOMBIA: PARAISOS FISCALES';
	}else if(paisParaisoFiscal === 'peru' ) {
		head = 'PERU: PAISES O TERRITORIOS DE BAJA O NULA IMPOSICION';
	}else if(paisParaisoFiscal === 'venezuela' ) {
		head = 'VENEZUELA: JURISDICCION DE BAJA IMPOSICION FISCAL';
	}


	const consultarTitulosRegimenes = async () => {
			const token = localStorage.getItem('fwstkn');
			const url = `https://webservice.fibolist.com/paraisosFiscales?token=${token}&pais=${paisParaisoFiscal}`;
			const api = await fetch(url);
			const resultado = await api.json();
			setResultado(resultado); 
			// console.log("resultado: ", resultado);
		}

	const spf = () => {
		setParaisosFiscales(false);
	}

	useEffect(() => {
		consultarTitulosRegimenes();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		consultarTitulosRegimenes();
		// eslint-disable-next-line
	}, [paisParaisoFiscal]);

	return ( 
		<Fragment>
			<div className="cita">
				<div align='center'><b>{head}</b></div><hr />

				{((typeof resultado === undefined) || (resultado === null) || (resultado === [])) 
				? <Spinner /> :
				// resultado.map(res => res.pais)
					resultado.map(res => (
						<Fragment>
						<div className="cita">
							<p>Territorio: <span>{ res.territorio }</span></p>
							<p>De acuerdo a: <span>{ res.de_acuerdo_a }</span></p>
							<p>Publicación: <span>{ res.publicacion }</span></p>
						</div>

						</Fragment>
					))
				}

			</div>
			<br />
			<button
					type 		= "button"
					className 	= "u-full-width button-primary"
					onClick		= { spf }
					>Regresar
				</button>
		</Fragment>
	 );
}
 
export default ParaisosFiscales;